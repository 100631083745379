<template>
  <main>
    <div id="ServiceDetails" class="pt-28 pb-5">
      <div class="details-container">
        <div v-if="serviceImage.length !== 0" class="sliders mb-6">
          <swiper
            :autoplay="autoplay"
            :effect="'coverflow'"
            :grabCursor="true"
            :centeredSlides="true"
            :slidesPerView="'auto'"
            :coverflowEffect="{
              rotate: 0,
              stretch: 65,
              depth: 100,
              modifier: 3,
              slideShadows: true,
            }"
            :modules="modules"
          >
            <swiper-slide v-for="img in serviceImage" :key="img.id">
              <a :href="baseUrl + img.img_path">
                <img
                  class="mx-auto w-full h-full object-cover"
                  :src="baseUrl + img.img_path"
                  alt="Sample 1"
                />
              </a>
            </swiper-slide>
          </swiper>
        </div>
        <div class="workInfo">
          <h1
            class="title font-bold font-title text-2xl mb-6"
            v-text="service.title[getLang]"
          ></h1>
          <p class="font-body text-justify" v-html="service.body[getLang]"></p>
        </div>
      </div>
    </div>
  </main>
</template>
<style></style>
<script>
import { Autoplay, EffectCoverflow } from "swiper";

export default {
  props: ["id"],
  data() {
    return {};
  },
  setup() {
    return {
      modules: [EffectCoverflow, Autoplay],
    };
  },
  methods: {},
  mounted() {
    this.getSingleService(this.id);
    this.getServiceImage(this.id);
  },
};
</script>
