<template lang="pug">
#workCard.rounded-lg.relative.overflow-hidden
    img(:src = "baseUrl + projectCardObj.primary_image" class = "duration-500 object-cover h-full")
    .overContent.bg-black.bg-opacity-60.flex.items-center.justify-center.absolute.inset-0.z-10.duration-500.top-0(class = "opacity-0")
        .info.text-white.text-center.min-h-min.p-3
            h2.font-bold.font-other(v-text="projectCardObj.title[getLang]", class="text-lg md:text-2xl")
            span( class= "inline-block bg-main text-white px-2 py-1 my-2 rounded" v-text = "projectCardObj.type[getLang]")
            p.px-4.font-body(v-text="convertToPlain(projectCardObj.sub_body[getLang])", class="text-sm md:text-base")
            router-link(:to = "'/product_details/' + projectCardObj.id" class = "font-bold pt-4 underline inline-block " v-text="localTrans('Read More','أقــراء اكــثر')" )
                
</template>
<style>
#workCard:hover img {
  transform: scale(1.1);
}
#workCard:hover .overContent {
  opacity: 1 !important;
}
</style>
<script>
export default {
  props: ["projectCardObj"],

  methods: {},
};
</script>
