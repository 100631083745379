import { createStore } from "vuex";
import SiteSittings from "./modules/SiteSittings.js";
import Services from "./modules/Services.js";
import Projects from "./modules/Projects.js";
import Partners from "./modules/Partners.js";
import Jobs from "./modules/Jobs.js";
import JobApplicant from "./modules/JobApplicant.js";
import SocialMedia from "./modules/SocialMedia.js";
import ProjectType from "./modules/ProjectType.js";
import ProjectImage from "./modules/ProjectImage.js";
import ServiceImage from "./modules/ServiceImage.js";
import WebsitPageInfo from "./modules/WebsitPageInfo.js";
export default createStore({
  state: {
    lang: localStorage.getItem("il_iq_lang") || "en",
  },
  getters: {
    getLang: (state) => state.lang,
  },
  mutations: {
    toggleLang(state) {
      if (state.lang == "en") {
        state.lang = "ar";
        localStorage.setItem("il_iq_lang", "ar");
        window.location.reload();
      } else {
        state.lang = "en";
        localStorage.setItem("il_iq_lang", "en");
        window.location.reload();
      }
    },
  },
  actions: {},
  modules: {
    SiteSittings,
    Services,
    Projects,
    ProjectType,
    ProjectImage,
    ServiceImage,
    Partners,
    Jobs,
    JobApplicant,
    WebsitPageInfo,
    SocialMedia,
  },
});
