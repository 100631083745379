<template lang="pug">
.service-card.border-b-4.border-main.shadow.py-12.rounded-md
    .icon.relative.w-16.mx-auto
        img(:src = "baseUrl + serviceCardObj.primary_image" class="mx-auto w-12 h-12 object-cover" )
        .rotating.absolute.border.border-sub.w-24.h-24(class = "top-1/2 left-1/2 rounded-full")
    .card-info.px-4.text-center.pt-8
        h2.font-other.text-xl.font-bold.my-3.h-16.flex.items-center.justify-center(v-text = "serviceCardObj.title[getLang]")
        p.font-body.read-more-text.text-gray-500(:id="`read-more-text-${serviceCardObj.id}`")
            | {{readMore(convertToPlain(serviceCardObj.body[getLang]))}}

            router-link( :to = "'/service_details/' + serviceCardObj.id" class = "text-blue-500 cursor-pointer hover:text-blue-800" v-text = "localTrans('Read More','إقرأ اكثر')")
        
</template>
<style scoped>
/* .service-card:hover {
  transition: ease-in 0.2s;
  transform: scale(1.025);
} */
</style>
<script>
export default {
  props: ["serviceCardObj"],
  methods: {
    readMore(text) {
      return text.slice(0, 200) + "... ";
    },
  },
};
</script>
