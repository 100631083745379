import $http from "@/plugins/axios.js";

export default {
  state: {
    projects: [],
    project: {
      title: {
        en: "",
        ar: "",
      },
      body: {
        en: "",
        ar: "",
      },
      sub_body: {
        en: "",
        ar: "",
      },
    },
  },
  getters: {
    projects: (state) => state.projects,
    project: (state) => state.project,
  },
  actions: {
    async getProjects({ commit }, filterData = { visible: "both" }) {
      const response = await $http.get(
        `/Projects?active=true&visible=${filterData.visible}`
      );
      response.data.map((project) => {
        project.title = {
          en: project.project_title_en,
          ar: project.project_title_ar,
        };

        project.body = {
          en: project.project_body_en,
          ar: project.project_body_ar,
        };

        project.type = {
          en: project.project_type_title_en,
          ar: project.project_type_title_ar,
        };

        project.sub_body = {
          en:
            project.project_body_en.length > 200
              ? project.project_body_en.slice(0, 200) + "..."
              : project.project_body_en,
          ar:
            project.project_body_ar.length > 200
              ? project.project_body_ar.slice(0, 200) + "..."
              : project.project_body_ar,
        };


        return project;
      });
      commit("SET_PROJECTS", response.data);
      return response.data
    },
    async getSingleProject({ commit }, id) {
      const response = await $http.get(`/Projects/${id}`);
      response.data.title = {
        en: response.data.project_title_en,
        ar: response.data.project_title_ar,
      };

      response.data.body = {
        en: response.data.project_body_en,
        ar: response.data.project_body_ar,
      };

      response.data.type = {
        en: response.data.project_type_title_en,
        ar: response.data.project_type_title_ar,
      };
      commit("SET_SINGLE_PROJECT", response.data);
    },
  },
  mutations: {
    SET_PROJECTS(state, data) {
      state.projects = data;
    },
    SET_SINGLE_PROJECT(state, data) {
      state.project = data;
    },
  },
};
