import $http from "@/plugins/axios.js";

export default {
  state: {
    projectTypes: [],
  },
  getters: {
    projectTypes: (state) => state.projectTypes,
  },
  actions: {
    async getProjectTypes({ commit }) {
      const response = await $http.get(`/ProjectTypes`);
      commit("SET_PROJECT_TYPES", response.data);
    },
  },
  mutations: {
    SET_PROJECT_TYPES(state, data) {
      state.projectTypes = data;
    },
  },
};
