import $http from "@/plugins/axios.js"

export default {
    state:{
        jobs:[]
    },
    getters:{
        jobs: state => state.jobs
    },
    actions:{
        async getJobs({commit},filterData={job_title:'' , department_id:0 ,active:'both'}){
            const response = await $http.get(`/jobs?job_title=${filterData.job_title}&department_id=${filterData.department_id}&active=${filterData.active}`)
            commit("SET_JOBS", response.data.map(item=>{
                item.sub_disc =  item.job_description.length > 30 ? item.job_description.slice(0, 30) + '...' : item.job_description
                return item
            }));
        },

        async createJobs({commit},job){
            const response = await $http.post('/jobs',job)
            response.data.obj.sub_disc =  response.data.obj.job_description.length > 30 ? response.data.obj.job_description.slice(0, 30) + '...' : response.data.obj.job_description
            commit('ADD_NEW_JOB',response.data.obj)
        },

        async updateJob({commit} , jobs){
            const response = await $http.put(`/jobs/${jobs.id}`,jobs)
            response.data.obj.sub_disc =  response.data.obj.job_description.length > 30 ? response.data.obj.job_description.slice(0, 30) + '...' : response.data.obj.job_description
            commit('UPDATE_JOB',response.data)
        },
        async deleteJob({commit} , id){
            const response = await $http.delete(`/jobs/${id}`)
            commit('DELETE_JOB',response.data.obj.id)
        }
    },
    mutations:{
        SET_JOBS(state,data){
            state.jobs = data
        },
        ADD_NEW_JOB(state,data){
            state.jobs.push(data)
        },
        UPDATE_JOB(state,data){
            state.jobs  = state.jobs.map(item => item = item.id == data.id ? data : item)
        },
        DELETE_JOB(state,id){
            state.jobs  = state.jobs.filter(item => item.id !== id)
        },
        
    }
}