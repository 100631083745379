import $http from "@/plugins/axios.js";

export default {
  state: {
    services: [],
    service: {
      title: {
        en: "",
        ar: "",
      },
      body: {
        en: "",
        ar: "",
      },
      sub_body: {
        en: "",
        ar: "",
      },
    },
  },
  getters: {
    services: (state) => state.services,
    service: (state) => state.service,
  },
  actions: {
    async getServices({ commit }) {
      const response = await $http.get(`/Services?active=true`);
      response.data.map((service) => {
        service.title = {
          en: service.service_title_en,
          ar: service.service_title_ar,
        };
        service.body = {
          en: service.body_en,
          ar: service.body_ar,
        };
      });
      commit("SET_SERVICES", response.data);
    },
    async getSingleService({ commit }, id) {
      const response = await $http.get(`/Services/${id}`);
      response.data.title = {
        en: response.data.service_title_en,
        ar: response.data.service_title_ar,
      };

      response.data.body = {
        en: response.data.body_en,
        ar: response.data.body_ar,
      };

      response.data.type = {
        en: response.data.service_type_title_en,
        ar: response.data.service_type_title_ar,
      };
      commit("SET_SINGLE_SERVICE", response.data);
    },
  },
  mutations: {
    SET_SERVICES(state, data) {
      state.services = data;
    },
    SET_SINGLE_SERVICE(state, data) {
      state.service = data;
    },
  },
};
