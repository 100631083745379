<template lang="pug">
#app(:class = "[getLang == 'ar' ? 'rtl' : '']")
    navbar(@showNavEvent="navReady = true")
    <router-view v-if="navReady" />
    myfooter(v-if="navReady")

    <loading :active="!navReady" :can-cancel="false" :is-full-page="fullPage" color="#6694E8"></loading>
</template>
<script>
import navbar from "./components/Global/_Navbar.vue";
import myfooter from "./components/Global/_Footer.vue";

export default {
  components: {
    navbar,
    myfooter,
  },

  data() {
    return {
      navReady: false,
    };
  },
  mounted() {
    this.getSocials();
  },

  methods: {
    // showNav() {
    //   this.navReady = true;
    // },
  },
};
</script>
