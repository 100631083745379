import $http from "@/plugins/axios.js"
import Swal from 'sweetalert2'
export default {
    state:{
        applicant:[]
    },
    getters:{
        applicant: state => state.applicant
    },
    actions:{
        async getApplicant({commit},filterData={email:'' , job_id:0 ,active:'both'}){
            const response = await $http.get(`/JobApplicants?email=${filterData.email}&job_id=${filterData.job_id}`)
            commit("SET_APPLICANT", response.data);
        },
        async createApplicant({commit},data){
            const response = await $http.post(`/JobApplicants`,data)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.message ,
                showConfirmButton: false,
                timer: 1500
            })
            return commit
        },
    },
    mutations:{
        SET_APPLICANT(state,data){
            state.applicant = data
        },
    }
}