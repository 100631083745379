<template>
  <div v-if="partners.length !== 0" class="parteners-container mx-auto md:mx-0">
      <!-- :centeredSlides="true" -->
    <swiper
      :loop="loop"
      :slides-per-view="4"
      :space-between="50"
      :autoplay="autoplay"
      @slideChange="onSlideChange"
      @doubleClick="ondblclick"
    >
      <swiper-slide v-for="partner in partners" :key="partner.id">
        <a :href="partner.url" class="partner-link">
          <img
            class="partner-img"
            :src="baseUrl + partner.icon_path"
            alt="Sample 1"
          /> </a
      ></swiper-slide>
    </swiper>
  </div>
</template>
<script>
// import "swiper/components/effect-cube/effect-cube.scss";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

export default {
  data() {
    return {
      loop: true,
      centeredSlides: true,
      slidesPerView: "auto",
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    };
  },
  methods: {
    onSlideChange() {},
    ondblclick() {},
  },
};
</script>

<style scoped>
.parteners-container {
  width: 30rem;
}
.partner-img {
  height: 2rem;
  object-fit: contain;
}
</style>
