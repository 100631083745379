<template lang="pug">
main
  #projectsPage.pb-10
      header(:style="{ backgroundImage: 'url(' + baseUrl + pageData.cover + ')' }")
          img.absolute.w-full.bottom-0(src = "../assets/Img/wave.svg")
      .works-content
          .container
              title-section(:title = 'pageData.title[getLang]' class = "pb-10 text-center"  :paragraph = 'pageData.description[getLang]')
              
              .work.grid.grid-cols-1.gap-5(class = "md:grid-cols-2 xl:grid-cols-3 w-10/12 md:w-full mx-auto")
                  work-card( v-for = "project in projects" :key="project.id" :projectCardObj='project' )
</template>
<style>
#projectsPage .work img {
  min-height: 400px;
}
</style>
<script>
export default {
  data() {
    return {
      pageData: {
        cover: "",
        title: {
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
      },
    };
  },
  mounted() {
    this.getProjects().then((data) => {
      console.log("projects");
      console.log(data);
    });
    this.getPagesInfo().then((data) => {
      console.log("pages info");
      console.log(data);
      this.getSectionData("Products", this.pages_info, "pageData");
    });
  },
};
</script>
