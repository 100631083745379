<template lang="pug">
nav#navbar.fixed.top-5.px-5.py-3.z-50.bg-white.rounded-sm.shadow.items-center.grid.grid-cols-1(class = " lg:grid-cols-2 lg:rounded-full w-11/12 left-1/2 transform -translate-x-1/2")
    .logo.flex.justify-between.items-center
        a(href="http://il-iq.com")
            img(src = "../../assets/Img/logo/logo.png" class = "w-24")
        .toggler.text-2xl.text-other(class = "lg:hidden cursor-pointer" @click="showList=!showList")
            <i class="fas fa-bars"></i>
    .list(:class = "[showList == false ? 'h-0 invisible' : ' h-auto visible ' , 'lg:visible lg:h-auto']")
        ul(class = " flex-col items-start flex lg:items-center justify-end lg:flex-row")
            li(v-if="NavbarContent !== null" class = "font-other font-bold  mr-4 mb-3 lg:mb-0 hover:text-main" v-for = "item in NavbarContent")
                router-link(:to = 'item.to' v-text = "item.title[getLang]" @click="closeNavs")
            li.relative(v-if="secondaryNavList.length !== 0", class="mr-4")
                .toggler.text-2xl.text-other(class = "hidden lg:inline-block cursor-pointer" @click="showSecondaryList=!showSecondaryList")
                    <i class="fas fa-bars"></i>
                .list(:class = "[(showSecondaryList == true) ? 'h-auto lg:block' : ' h-auto hidden ' , 'lg:absolute lg:bg-white lg:p-6 lg:right-4 lg:top-14 lg:w-48']")
                    ul(class = "flex-col items-start flex justify-start")
                        li(v-if="secondaryNavList !== null" class = "font-other font-bold  mr-4 mb-3 hover:text-main inline-block" v-for = "item in secondaryNavList")
                            router-link(:to = 'item.to' v-text = "item.title[getLang]" @click="closeNavs")
                .list(:class = "[(showList == true) ? 'h-auto visible lg:invisible' : ' h-auto invisible ' , 'lg:absolute lg:bg-white lg:p-6 lg:right-4 lg:top-14 lg:w-48']")
                    ul(class = "flex-col items-start flex justify-start")
                        li(v-if="secondaryNavList !== null" class = "font-other font-bold  mr-4 mb-3 lg:mb-0 hover:text-main inline-block" v-for = "item in secondaryNavList")
                            router-link(:to = 'item.to' v-text = "item.title[getLang]" @click="closeNavs")
            li( class = "font-other font-bold mr-3 ring-2 ring-main px-5 py-1 cursor-pointer hover:bg-main hover:text-white" @click = "toggleLang()" v-text = "getLang == 'en' ? 'AR' : 'EN' ")
</template>
<script>
export default {
  emits: ["showNavEvent"],
  data() {
    return {
      showSecondaryList: false,
      showList: false,
      NavbarContent: [],
      secondaryNavList: [],
    };
  },
  methods: {
    closeNavs() {
      this.showSecondaryList = false;
      this.showList = false;
    },
  },

  mounted() {
    this.getPagesInfo({ title: "", active: true }).then((data) => {
      data.forEach((page) => {
        if (page.title.en.toLowerCase() === "home") {
          page.to = "/";
          this.NavbarContent.push(page);
        } else if (
          page.title.en.toLowerCase() === "services" ||
          page.title.en.toLowerCase() === "jobs" ||
          page.title.en.toLowerCase() === "products"
        ) {
          page.to = `/${page.title.en.toLowerCase()}`;
          this.NavbarContent.push(page);
        } else if (page.title.en.toLowerCase() === "about") {
          page.to = `/page/${page.title.en.toLowerCase()}`;
          this.NavbarContent.push(page);
        } else {
          page.to = `/page/${page.title.en.toLowerCase()}`;
          this.secondaryNavList.push(page);
        }
      });

      this.$emit("showNavEvent");
    });

    
  },
};
</script>
