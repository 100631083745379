import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Services from "../views/Services.vue";
import Projects from "../views/Projects.vue";
import WorkDetails from "../views/WorkDetails.vue";
import ServiceDetails from "../views/ServiceDetails.vue";
import Jobs from "../views/Jobs.vue";
import DynamicPage from "../views/DynamicPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/products",
    name: "Projects",
    component: Projects,
  },
  {
    path: "/service_details/:id",
    name: "ServiceDetails",
    component: ServiceDetails,
    props: true,
  },
  {
    path: "/product_details/:id",
    name: "WorkDetails",
    component: WorkDetails,
    props: true,
  },
  {
    path: "/jobs",
    name: "jobs",
    component: Jobs,
  },
  {
    path: "/page/:title",
    name: "DynamicPage",
    component: DynamicPage,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
