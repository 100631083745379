import $http from "@/plugins/axios.js"

export default {
    state:{
        socials:[]
    },
    getters:{
        socials: state => state.socials
    },
    actions:{
        async getSocials({commit}){
            const response = await $http.get(`/SocialMedias`)
            commit("SET_SOCIAL", response.data);
        },
    },
    mutations:{
        SET_SOCIAL(state,data){
            state.socials = data
        },
    }
}