import $http from "@/plugins/axios.js"

export default {
    state:{
        siteSittings:{
            title:{
                en:'',
                ar:'',
            },
            description:{
                en:'',
                ar:'',
            },
        }
    },
    getters:{
        siteSittings: state => state.siteSittings
    },
    actions:{
        async getSiteSittings({commit}){
            const response = await $http.get(`/Sitesettings`)
            response.data.map(item=>{
                item.title={
                    en:item.website_title_en,
                    ar:item.website_title_ar,
                }
                item.description={
                    en:item.website_description_en,
                    ar:item.website_description_ar,
                }
            })
            commit("SET_SITE_SITTINGS", response.data[0]);
        },

        async updateSiteSittings({commit} , data){
            const response = await $http.put(`/Sitesettings/${data.id}`,data.data)
            commit('UPDATE_USER',response.data)
        }
    },
    mutations:{
        SET_SITE_SITTINGS(state,data){
            state.siteSittings = data
        },
        UPDATE_SITE_SITTINGS(state,data){
            state.siteSittings  = state.siteSittings.map(item => item = item.id == data.id ? data : item)
        },
        
    }
}