import $http from "@/plugins/axios.js";

export default {
  state: {
    serviceImage: [],
  },
  getters: {
    serviceImage: (state) => state.serviceImage,
  },
  actions: {
    async getServiceImage({ commit }, id) {
      const response = await $http.get(`/ServiceImages?service_id=${id}`);
      commit("SET_SERVICE_IMAGE", response.data);
    },
  },
  mutations: {
    SET_SERVICE_IMAGE(state, data) {
      state.serviceImage = data;
    },
  },
};
