import $http from "@/plugins/axios.js"

export default {
    state:{
        partners:[]
    },
    getters:{
        partners: state => state.partners
    },
    actions:{
        async getPartners({commit}){
            const response = await $http.get(`/Parteners?active=true`)
            commit("SET_PARTNERS", response.data);
        },
    },
    mutations:{
        SET_PARTNERS(state,data){
            state.partners = data
        },
    }
}