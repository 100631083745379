<template lang="pug">
main
  #projectsPage.pb-10
      .applicInfo.fixed.bg-black.bg-opacity-40.inset-0.flex.items-center.justify-center.z-50(class = "" v-show = "showForm")
          form( class = "bg-white grid grid-cols-1 w-2/4 gap-2 p-5" )
              .cv(class = "w-full bg-gray-200 flex items-center justify-center curoser-pointer relative" )
                  //- h1.font-bold  Upload Your Cv Pdf / Docx
                  div.img-section(class="w-full")
                      .upload(class="h-40 bg-gray-200 border-2 border-gray-500 border-dashed text-white shadow-lg flex items-center justify-center flex-col relative")
                          input(type="file", @change="getCv($event)", accept = 'application/pdf', class="absolute inset-0 w-full h-full opacity-0 cursor-pointer")
                          i(class="fas fa-cloud-upload-alt text-4xl text-gray-500")
                          span(class="text-xl text-gray-500 underline") Upload Your Cv Pdf
                  //- input( type = "file" class = "opacity-0 absolute inset-0 h-full w-full cursor-pointer" @change = "getCv($event)" placeholder = "Full Name" accept = 'document/pdf')
              .hint.col-span-full
                  a( :href = "ShowCvLink" v-show = " ShowCvLink !== '' ? true : false" class = "font-bold text-sm cursor-pointer hover:text-green-500 duration-500"  target = "_blank") Cv Preview
              .input(class = "")
                  input( class = "border rounded h-10 px-2 duration-500 outline-none focus:border-main bg-gray-100 font-bold w-full" v-model = "postData.full_name" placeholder = "Full Name")
              .input(class = "")
                  input( class = "border rounded h-10 px-2 duration-500 outline-none focus:border-main bg-gray-100 font-bold w-full" v-model = "postData.email" placeholder = "Email")
              .input(class = "")
                  input( class = "border rounded h-10 px-2 outline-none duration-500 focus:border-main bg-gray-100 font-bold w-full" v-model = "postData.phone_no" placeholder = "Phone Number")
              .controller.py-5
                  button(class = "px-3 py-2 border-main border-2 bg-main font-bold  text-white transform hover:-translate-y-2 duration-500" @click.prevent="sendData" :class = "[loading ? 'opacity-50 cursor-not-allowed' : '']") Apply
                  button(class = "px-3 py-2 border-2 border-main font-bold mx-2 text-main transform hover:-translate-y-2 duration-500" @click.prevent = "showForm = false") Close
      header(:style="{ backgroundImage: 'url(' + baseUrl + pageData.cover + ')' }")
          img.absolute.w-full.bottom-0(src = "../assets/Img/wave.svg")
      .works-content
          .container
              title-section(:title = 'pageData.title[getLang]' class = "pb-10 text-center"  :paragraph = 'pageData.description[getLang]')
              


              .jobs.grid.grid-cols-1.gap-5(class = "md:grid-cols-2 w-10/12 mx-auto")
                  .job-card(class = "bg-gray-100 rounded border p-5" v-for = "job in jobs")
                      .title
                          h2.job-title.font-bold.text-xl.font-title {{job.job_title}}
                      p(class = "font-Maitree border-l-2 px-5 my-3" v-html="job.job_description")
                      button(class = "px-3 py-2 bg-main font-bold text-white transform hover:-translate-y-2 duration-500" @click = "request(job)") Apply

</template>
<style>
#projectsPage .work img {
  min-height: 400px;
}
</style>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading: false,
      showForm: false,
      ShowCvLink: "",
      pageData: {
        cover: "",
        title: {
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
      },
      postData: {
        attachment: "",
        full_name: "",
        email: "",
        phone_no: "",
        job_id: "",
      },
    };
  },
  methods: {
    getCv(event) {
      this.postData.attachment = event.target.files[0];
      this.ShowCvLink = URL.createObjectURL(event.target.files[0]);
    },
    request(job) {
      this.postData.job_id = job.id;
      this.showForm = true;
    },
    sendData() {
      this.loading = true;
      var data = new FormData();
      data.append("attachment", this.postData.attachment);
      data.append("full_name", this.postData.full_name);
      data.append("email", this.postData.email);
      data.append("phone_no", this.postData.phone_no);
      data.append("job_id", this.postData.job_id);

      this.createApplicant(data)
        .then(() => {
          this.loading = false;
          this.showForm = false;
          (this.ShowCvLink = ""),
            (this.postData = {
              attachment: "",
              full_name: "",
              email: "",
              phone_no: "",
              job_id: "",
            });
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            showConfirmButton: false,
            text: error.response.data.message,
            footer: "",
          });
        });
    },
  },
  mounted() {
    this.getJobs();
    this.getPagesInfo().then(() => {
      this.getSectionData("Jobs", this.pages_info, "pageData");
    });
  },
};
</script>
