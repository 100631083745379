import $http from "@/plugins/axios.js"

export default {
    state:{
        projectImage:[]
    },
    getters:{
        projectImage: state => state.projectImage
    },
    actions:{
        async getProjectImage({commit} , id){
            const response = await $http.get(`/ProjectImages?project_id=${id}`)
            commit("SET_PROJECT_IMAGE", response.data);
        },
    },
    mutations:{
        SET_PROJECT_IMAGE(state,data){
            state.projectImage = data
        },
    }
}