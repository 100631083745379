<template lang="pug">
main
  #dynamicPage.pb-10(v-if="pageData !== null && pageData !== undefined ")
      header(:style="{ backgroundImage: 'url(' + baseUrl + pageData.cover + ')' }")
          img.absolute.w-full.bottom-0(src = "../assets/Img/wave.svg")
      .page-content
          .container
              title-section(:title = 'pageData.title[getLang]' class = "pb-10 text-center"  paragraph = '')
              
              p(class="font-body text-justify mb-6 max-w-4xl mx-auto", v-html="pageData.description[getLang]")
  .loading.w-full.h-screen.flex.items-center.justify-center.text-2xl(v-else-if="pageData === undefined",v-text="pageNotFound[getLang]") 
</template>
<script>
export default {
  props: ["title"],
  data() {
    return {
      pageData: null,
      pageNotFound: {
        en: "Page not found!",
        ar: "الصفحة غير موجودة!",
      },
    };
  },
  mounted() {
    
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getPagesInfo({ title: this.$route.params.title }).then((data) => {
          this.pageData = data[0];
        });
      },
    },
  },
};
</script>
