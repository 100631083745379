<template lang="pug">
main
  #HomePage
    header.h-screen.flex.items-center.relative.overflow-hidden(:class = "[ getLang == 'en' ? 'text-center lg:text-left' : 'text-center lg:text-right']")
      .container
        .header-content
          .info.relative.z-40
            h1.font-title.font-bold(v-text = "siteSittings.title[getLang]", class="text-3xl md:text-5xl")
            p( class = "font-body text-text md:text-xl w-full lg:w-2/5 py-4 " v-html="siteSittings.description[getLang]", id="myp")
            button(class = "w-36 h-12 rounded bg-main text-white" @click="getStarted()" v-text = "localTrans('Get Started','تــصفح اكثر')") 
          .media.absolute(class = "rounded-full overflow-hidden hide lg:block" :class = "[getLang == 'en' ? '-top-32 -right-32' : '-top-32 -left-32' ]")
            .video.w-full.h-full
              img(:src = "baseUrl + siteSittings.icon_path" v-if = "siteType == 'image'" class = "w-full h-full object-cover")
              <video class = "w-full h-full object-cover" loop = "loop" autoplay = "autoplay">
                <source :src = "baseUrl + siteSittings.icon_path" type="video/mp4">
              </video>
          .partner.mt-20

            <partener-slider :partners="partners" ></partener-slider>

    #About.py-10.bg-back
      .container.grid.grid-cols-1.gap-x-10(class = "lg:grid-cols-2")
        .imgs.grid.grid-cols-2.grid-rows-2.gap-x-1.justify-center.justify-end.overflow-hidden
            <img :class = "[index == 1 ? 'mt-10' : '' , index == 2 ? '-mt-10' : ''  , 'h-full mb-1 w-full object-cover' ] "  v-for = "(item,index) in page_info.websitePageImage" :src="baseUrl + item.img_path" alt="Sample 2">
        .about-info
          title-section(:title = 'page_info.title[getLang]' class = "pb-10"  paragraph = '')
          p.font-body.pb-5.about-text(class = "text-justify" v-html = 'page_info.description[getLang]')

    #Services.py-10
      .container
        title-section(:title = 'ServicesData.title[getLang]' class = "pb-10 text-center"  paragraph = '')
        .services.grid.grid-cols-1.gap-5(class = "md:grid-cols-2 lg:grid-cols-3 w-full sm:w-10/12 mx-auto")
          service-card( v-for = "(service,index) in services" :key = "service.id" :serviceCardObj='service' v-show = "index < 3 ? true : false")
        .section-controll.py-10.flex.justify-center.w-full
          router-link( to = "/services" class = "w-28 h-12  rounded bg-main text-white flex items-center justify-center font-bold" v-text = "localTrans('View More','تــصفح اكثر')")
    #Work.py-10.bg-back
      .container
        .info(class = "text-center")
          title-section(:title = "localTrans('Our Products','منتجاتنا')" class = "pb-10"  :paragraph="localTrans('Innovation Legend','أســطورة الابــداع')")
          swiper.home-work-swiper(:autoplay="autoplay" :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :slidesPerView="'auto'" :coverflowEffect="{rotate: 0,stretch: 65,depth: 100,modifier: 3,slideShadows: true,}" :modules="modules")
            swiper-slide(v-for = "project in projects" :key="project.id")
              work-card( :projectCardObj='project')



          
        .section-controll.py-10.flex.justify-center.w-full
          router-link( to = "/products" class = "w-28 h-12  rounded bg-main text-white flex items-center justify-center font-bold" v-text = "localTrans('View More','تــصفح اكثر')")
    #contacts.py-8
      .container.grid.grid-cols-1.gap-10(class = "lg:grid-cols-2")
        .map
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1352.245100851488!2d44.449561889660245!3d33.30758472836591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15578162fee1730d%3A0x7cdb60f874270235!2z2YXYrNmF2Lkg2KfZhNmB2YrYtdmEINin2YTYqtis2KfYsdmK!5e0!3m2!1sar!2siq!4v1641899379767!5m2!1sar!2siq" class = "w-full min-h-40" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        .contact_info.py-10(class = "")
          title-section(:title = "localTrans('Keep In Touch','تـــواصـــل معنا')" class = "pb-10"  :paragraph="localTrans('With Us ','')")
          ul(class = "contact-list")
            li(class = "contact-list-item")
              .icon.relative.w-12
                img(src = "../assets/Img/contact/location.svg" class="object-contain h-6 mx-auto w-4" )
                .rotating.absolute.border.border-sub.w-12.h-12(class = "top-1/2 left-1/2 rounded-full")
              .contct-info.font-other.text-md(class="md:px-5")
                span {{siteSittings.address}}
            li(class = "contact-list-item")
              .icon.relative.w-12
                img(src = "../assets/Img/contact/phone.svg" class="mx-auto w-4" )
                .rotating.absolute.border.border-sub.w-12.h-12(class = "object-contain h-6 top-1/2 left-1/2 rounded-full")
              .contct-info.font-other.text-md(class="md:px-5")
                span {{siteSittings.phone_number}}
            li(class = "contact-list-item")
              .icon.relative.w-12
                img(src = "../assets/Img/contact/Email.svg" class="object-contain h-6 mx-auto w-4" )
                .rotating.absolute.border.border-sub.w-12.h-12(class = "top-1/2 left-1/2 rounded-full")
              .contct-info.font-other.text-md(class="md:px-5")
                span {{siteSittings.email}}
</template>
<style scoped>
.contact-list {
  display: grid;
  grid-template-rows: repeat(3, auto);
}
.contact-list-item {
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  /* justify-content: center; */
  align-items: start;
  align-content: start;
  height: 5rem;
  min-height: min-content;
}

.media {
  height: 700px;
  width: 700px;
  box-shadow: 26px 12px 0px 25px #b9cacfa8;
}
#Work #workCard {
  margin: 0px auto;
  height: 435px;
}

#Work #workCard p {
  @apply text-lg;
}

#Work #workCard img {
  width: 100%;
  height: 100%;
}
#contacts .rotating::before {
  top: 3px;
}
#contacts .rotating::after {
  bottom: 3px;
}
.container {
  width: 90%;
}
@media (min-wdith: 1280px) {
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.swiper-coverflow.home-work-swiper .swiper-slide {
  width: 450px;
  height: 100%;
}
.swiper-coverflow.home-work-swiper .swiper-slide img {
  width: 450px;
  display: block;
  height: 100%;
}

@media (max-width: 460px) {
  .swiper-coverflow.home-work-swiper .swiper-slide {
    max-width: 330px;
    width: 100%;
    height: 330px;
    box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.1);
  }

  #Work #workCard {
    margin: 0px auto;
    height: 330px;
  }

  .contact-list-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2.5rem 1fr;
    /* justify-content: center; */
    align-items: start;
    align-content: start;
    height: unset;
    min-height: min-content;
  }
  .contact-list-item .contct-info {
    margin: 0rem 0 3rem;
  }
}
</style>
<script>
import { Autoplay, EffectCoverflow } from "swiper";

export default {
  data() {
    return {
      workCatg: ["Web Dev.", "Mobile Apps Dev.", "Ip Tv", "Network"],
      siteType: "video",
      ServicesData: {
        title: {
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
      },
    };
  },
  setup() {
    return {
      modules: [EffectCoverflow, Autoplay],
    };
  },
  methods: {
    setAboutSectionHeight() {
      const imgs = document.querySelector("#About .imgs");
      const infoTite = document.querySelector(
        "#About .about-info .section-title"
      );
      const infoText = document.querySelector("#About .about-info .about-text");

      const infoHeight =
        infoTite.getBoundingClientRect().height +
        infoText.getBoundingClientRect().height;
      imgs.style.height = `${infoHeight}px`;
    },
    sliderConfig() {
      var config = {
        rewind: true,
        autoplay: true,
        direction: this.getLang == "ar" ? "rtl" : "ltr",
        pagination: false,
        width: 700,
        speed: 800,
        easing: "cubic-bezier(0.65, 0.05, 0.38, 0.99)",
        perPage: 4,
        perMove: 2,
        center: true,
        arrows: true,
      };

      return config;
    },
    sliderConfig_2() {
      var config = {
        rewind: true,
        gap: 10,
        autoplay: false,
        pagination: false,
        speed: 800,
        direction: this.getLang == "ar" ? "rtl" : "ltr",
        easing: "cubic-bezier(0.65, 0.05, 0.38, 0.99)",
        perPage: 1,
        center: true,
        arrows: true,
      };

      return config;
    },
    getStarted() {
      window.scrollTo({
        top: document.querySelector("#About").offsetHeight,
        behavior: "smooth",
      });
    },
  },
  watch: {
    siteSittings(value) {
      if (
        value.icon_path.endsWith("png") ||
        value.icon_path.endsWith("jpg") ||
        value.icon_path.endsWith("jpge")
      ) {
        this.siteType = "image";
      } else {
        this.siteType = "video";
      }
    },
  },
  mounted() {
    this.getSiteSittings();
    this.getPartners();
    this.getPageInfo(3).then(() => {
      this.setAboutSectionHeight();
      window.addEventListener("resize", () => {
        this.setAboutSectionHeight();
      });
    });
    this.getServices();
    this.getPagesInfo().then(() => {
      this.getSectionData("Services", this.pages_info, "ServicesData");
    });
    this.getProjects({ visible: true });
  },

  //   mounted() {
  // },
  // methods: {

  // },
};
</script>
