<template lang="pug">
.section-title
    h1(class = "font-title text-5xl font-bold") {{title}}
    p(class = "font-title font-bold" v-html="paragraph")
</template>
<script>
export default {
  props: ["title", "paragraph"],
};
</script>
