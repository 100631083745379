import $http from "@/plugins/axios.js";

export default {
  state: {
    pages_info: [],
    page_info: {
      title: { en: "", ar: "" },
      description: { en: "", ar: "" },
    },
  },
  getters: {
    pages_info: (state) => state.pages_info,
    page_info: (state) => state.page_info,
  },
  actions: {
    async getPagesInfo({ commit }, filterData = { title: "", active: true }) {
      const response = await $http.get(
        `/WebsitePages?title=${filterData.title}&active=${filterData.active}`
      );
      response.data.map((info) => {
        info.title = {
          ar: info.title_ar,
          en: info.title_en,
        };
        info.description = {
          ar: info.description_ar,
          en: info.description_en,
        };

        if (info.websitePageImage.length !== 0) {
          info.cover =
            info.websitePageImage[info.websitePageImage.length - 1].img_path;
        }
        return info;
      });
      commit("SET_PAGES_INFO", response.data);
      return response.data;
    },

    async getPageInfo({ commit }, id) {
      const response = await $http.get(`/WebsitePages/${id}`);
      response.data.title = {
        en: response.data.title_en,
        ar: response.data.title_ar,
      };
      response.data.description = {
        en: response.data.description_en,
        ar: response.data.description_ar,
      };
      commit("SET_PAGE_INFO", response.data);
      return;
    },

    async createPageInfo({ commit }, page) {
      const response = await $http.post("/WebsitePages", page);
      response.data.obj.sub_disc =
        response.data.obj.description_en.length > 30
          ? response.data.obj.description_en.slice(0, 30) + "..."
          : response.data.obj.description_en;
      commit("ADD_NEW_PAGE_INFO", response.data.obj);
    },

    async updatePageInfo({ commit }, page) {
      const response = await $http.put(`/WebsitePages/${page.id}`, page);
      if (page.id !== 4) {
        response.data.obj.sub_disc =
          response.data.obj.description_en.length > 30
            ? response.data.obj.description_en.slice(0, 30) + "..."
            : response.data.obj.description_en;
        commit("UPDATE_PAGE_INFO", response.data.obj);
      }
    },
    async deletePageInfo({ commit }, id) {
      const response = await $http.delete(`/WebsitePages/${id}`);
      commit("DELETE_PAGE_INFO", response.data.obj.id);
    },
  },
  mutations: {
    SET_PAGES_INFO(state, data) {
      state.pages_info = data;
    },
    SET_PAGE_INFO(state, data) {
      state.page_info = data;
    },
    ADD_NEW_PAGE_INFO(state, data) {
      state.pages_info.push(data);
    },
    UPDATE_PAGE_INFO(state, data) {
      state.pages_info = state.pages_info.map(
        (item) => (item = item.id == data.id ? data : item)
      );
    },
    DELETE_PAGE_INFO(state, id) {
      state.pages_info = state.pages_info.filter((item) => item.id !== id);
    },
  },
};
