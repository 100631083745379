import axios from "axios";

axios.interceptors.request.use((config) => {
  config.baseURL = "https://il-iq.com/api/api/";
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
