import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { mapGetters, mapActions, mapMutations } from "vuex";

import "./styles/index.css";
// import "https://cdn.lordicon.com/libs/mssddfmo/lord-icon-2.1.0.js"
import VueSplide from "@splidejs/vue-splide";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import _Title from "./components/Global/_Title.vue";
import _ServiceCard from "./components/Global/_ServiceCard.vue";
import _WorkCard from "./components/Global/_WorkCard.vue";
import _PartenerSlider from "./components/Global/_PartenerSlider.vue";
import { Swiper } from "swiper/vue/swiper-vue";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import "swiper/swiper.min.css";

import "@splidejs/splide/dist/css/splide.min.css";
// Import component
import Loading from "vue3-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
var mixin = {
  data() {
    return {
      baseUrl: "https://il-iq.com/api/",
      loop: true,

      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getLang",
      "siteSittings",
      "services",
      "service",
      "serviceImage",
      "projects",
      "projectTypes",
      "projectImage",
      "partners",
      "socials",
      "jobs",
      "applicant",
      "pages_info",
      "page_info",
      "project",
    ]),
  },
  mounted() {
    this.pageScrolling();
  },
  methods: {
    ...mapMutations(["toggleLang"]),
    ...mapActions([
      "getSiteSittings",

      "getServices",
      "getServiceImage",

      "getSingleService",

      "getProjects",

      "getSingleProject",

      "getProjectTypes",

      "getProjectImage",

      "getPartners",

      "getSocials",

      "getJobs",
      "createApplicant",

      "getPagesInfo",
      "getPageInfo",
    ]),
    convertToPlain(rtf) {
      let div = document.createElement("div");
      div.innerHTML = rtf;
      let text = div.textContent || div.innerText || "";
      return text;
    },
    localTrans(key_en, key_ar) {
      return this.getLang == "en" ? key_en : key_ar;
    },
    getSectionData(section, array, key) {
      this[key] = array.find((item) => {
        return item.title_en.includes(section);
      });
    },
    pageScrolling() {
      if (window.scrollY > 0) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};

createApp(App)
  .component("Splide", Splide)
  .component("SplideSlide", SplideSlide)
  .component("title-section", _Title)
  .component("service-card", _ServiceCard)
  .component("work-card", _WorkCard)
  .component("partener-slider", _PartenerSlider)
  .component("swiper-slide", SwiperSlide)
  .component("swiper", Swiper)
  .component("loading", Loading)
  .mixin(mixin)
  .use(store)
  .use(router)
  .use(VueSplide)
  .mount("#app");
