<template lang="pug">
main
  #servicesPage.pb-10
      header(:style="{ backgroundImage: 'url(' + baseUrl + pageData.cover + ')' }")
          img.absolute.w-full.bottom-0(src = "../assets/Img/wave.svg")
      .services-content
          .container
              title-section(:title = 'pageData.title[getLang]' class = "pb-10 text-center"  paragraph = '')
              .services.grid.grid-cols-1.gap-5(class = "md:grid-cols-2 lg:grid-cols-3 w-10/12 mx-auto")
                  service-card( v-for = "(service,index) in services" :key = "service.id" :serviceCardObj='service')
</template>
<script>
export default {
  data() {
    return {
      pageData: {
        title: {
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
      },
    };
  },
  mounted() {
    this.getServices();
    this.getPagesInfo().then(() => {
      this.getSectionData("Services", this.pages_info, "pageData");
    });
    
  },
};
</script>
